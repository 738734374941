export const roleGroups = [
  { key: "administrator", value: "Riskomer Yönetim/İdari" },
  { key: "customer", value: "Müşteri" },
  { key: "physician", value: "İSG Personeli" },
];

export const roleGroupLabels = {
  administrator: "Riskomer Yönetim/İdari",
  customer: "Müşteri",
  physician: "İSG Personeli",
};
