<template>
  <app-overlay>
    <searchable-table :items="errors" :fields="fields">
      <template #modalbutton>
        <app-button @click="clearLogs" text="Clear Logs" size="md" icon="TrashIcon" />
      </template>
      <template #row-details="data">
        <div class="overflow-scroll" style="max-width: 1200px">
          <prism language="markup"> {{ JSON.stringify(data.item, null, 3) }} </prism>
        </div>

        <!--  <div class="overflow-scroll" style="max-width: 1200px">
        </div> -->
      </template>
      <template #actions="data"> <app-icon-button variant="gradient-primary" @click="data.toggleDetails()" icon="ChevronDownIcon" /> </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import BCardCode from "@/@core/components/b-card-code/BCardCode.vue";
import { baseURL } from "@/utils/appAxios";
export default {
  props: {
    level: String,
    required: true,
  },
  components: {
    Prism,
    BCardCode,
  },
  data() {
    return {
      errors: [],
      fields: [
        { key: "service", label: "Service" },
        { key: "message.route", label: "Route" },
        { key: "message.date", label: "Date", formatDateTime: true },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  methods: {
    getData() {
      this.$axios({ method: "GET", url: `https://api.riskomer.com/logs/${this.level}`, baseURL }, { loading: "table" })
        .then((result) => {
          this.errors = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearLogs() {
      this.$axios({ method: "GET", url: `https://api.riskomer.com/logs/clear-logs/${this.level}`, baseURL }, { loading: "table" })
        .then(() => {
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
