<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="items" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-button @click="showNewPermissionForm" text="Add Permission" icon="PlusIcon" size="md" />
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import NewPermissionForm from "./NewPermissionForm.vue";
export default {
  data() {
    return {
      filters: {},
      items: [],
      total: 0,
      fields: [
        { key: "title", label: "title" },
        { key: "subTitle", label: "subTitle" },
        { key: "group", label: "group" },
        { key: "action", label: "action" },
        { key: "subject", label: "subject" },
        { key: "actions", label: "actions" },
      ],
    };
  },
  methods: {
    getData() {
      this.$axios
        .get("/permissions/list", { params: { ...this.filters, searchFields: JSON.stringify(["title", "subTitle", "group", "action", "subject"]) }, loading: "table" })
        .then((result) => {
          this.items = result?.data?.data ?? [];
          this.total = result?.data?.total ?? 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showNewPermissionForm() {
      this.$showAppSidebar("New Permission", NewPermissionForm, { callback: this.getData });
    },
  },
};
</script>

<style></style>
