<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="newPermissionForm">
      <app-select-input v-model="group" name="Grup" :options="groups" select_label="value" label="Grup:" placeholder="Grup Seçiniz..." :reduce="(option) => option.key" />
      <app-input v-model="title" name="title" label="Title:" placeholder="Title..." rules="required" />
      <app-input v-model="subTitle" name="subTitle" label="Sub Title:" placeholder="Sub Title..." />
      <app-select-input v-model="selected" name="Action" :options="actionOptions" label="Action:" placeholder="Action..." multiple />
      <app-input v-model="subject" name="subject" label="Subject:" placeholder="Subject..." rules="required" />
      <app-button @click="addPermission" text="Add" icon="PlusIcon" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { roleGroups } from "../Settings/RoleManagement/roleUtils";
export default {
  components: { ValidationObserver },
  data() {
    return {
      group: null,
      title: "",
      subTitle: "",
      action: "",
      subject: "",
      groups: roleGroups,
      actionOptions: ["create", "read", "write", "delete"],
      selected: [],
    };
  },
  computed: {
    callback() {
      return this.$store?.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    addPermission() {
      this.$validate(this.$refs?.newPermissionForm, () => {
        this.$axios
          .post("/permissions/add", { data: this.selected.map((item) => this.reduceSelected(item)) })
          .then(() => {
            this.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    reduceSelected(option) {
      return {
        title: this.title,
        subTitle: this.subTitle,
        action: option,
        subject: this.subject,
        group: this.group ?? undefined,
      };
    },
  },
};
</script>

<style></style>
