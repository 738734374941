<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="items" :fields="fields" :total-rows="total" searchable>
      <template #modalbutton>
        <app-dropdown offset="0">
          <template #button-content>
            <app-button text="Manual Backup" size="md" :loading="makingBackup" />
          </template>
          <b-dropdown-item @click="manualBackup('data')"> Data </b-dropdown-item>
          <b-dropdown-item @click="manualBackup('option_data')"> Option Data </b-dropdown-item>
        </app-dropdown>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item)"> Download </b-dropdown-item>
          <b-dropdown-item @click="deleteFile(item)"> Delete </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
      makingBackup: false,
      items: [],
      fields: [
        { key: "backupType", label: "Backup Type" },
        { key: "createdAt", label: "Created At", formatDateTime: true },
        { key: "actions", label: "actions" },
      ],
      total: 0,
    };
  },
  methods: {
    getData() {
      this.$axios
        .get("/backups/list", { params: { ...this.filters }, loading: "table" })
        .then((result) => {
          this.items = result?.data?.data;
          this.total = result?.data?.total;
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
        });
    },
    getStatus() {
      this.$axios
        .get("/backups/status")
        .then((result) => {
          this.makingBackup = result.status === 201;
        })
        .catch((err) => {
          this.makingBackup = false;
          console.log(err);
        });
    },
    manualBackup(type) {
      this.makingBackup = true;
      this.$axios
        .get(`/backups/start-backup/${type}`)
        .then((result) => {
          this.makingBackup = result.status === 201;
        })
        .catch((err) => {
          this.makingBackup = false;
          console.log(err);
        });
    },
    downloadFile(item) {
      this.$axios
        .get(`/backups/download/${item?._id}`, { loading: "table", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], { type: result.headers["content-type"] });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = item?.file;
          link.click();
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { variant: "danger" });
        });
    },
    deleteFile(item) {
      this.$confirm({ message: "Kayıt silinecek emin misiniz" }, () => {
        this.$axios
          .delete(`/backups/delete/${item?._id}`, { loading: "table" })
          .then(() => {
            this.getData();
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { variant: "danger" });
          });
      });
    },
  },
  mounted() {
    this.getStatus();

    this.$socket.on("BACKUP", ({ status }) => {
      if (status === 200) this.getData();
      this.makingBackup = status === 201;
    });
  },
  destroyed() {
    this.$socket.off("BACKUP");
  },
};
</script>

<style></style>
