<template>
  <div>
    <div class="d-flex gap-10 flex-wrap">
      <app-button size="md" @click="newVersion('admin')" text="New Admin Version" />
      <app-button size="md" @click="newVersion('panel')" text="New Panel Version" />
    </div>
    <hr />
    <b-custom-tabs :tabs="tabs" lazy-once>
      <template #errorLogs> <logs level="error" /></template>
      <template #infoLogs> <logs level="info" /></template>
    </b-custom-tabs>
  </div>
</template>

<script>
import { baseURL } from "@/utils/appAxios";
import Logs from "./Logs.vue";
import Backups from "./Backups.vue";
import Permissions from "./Permissions.vue";

export default {
  components: { Logs },
  data() {
    return {
      tabs: [
        { key: "errorLogs", title: "Error Logs" },
        { title: "Backups", component: Backups },
        { key: "infoLogs", title: "Info Logs" },
        { title: "Permissions", component: Permissions },
      ],
    };
  },
  methods: {
    newVersion(versionType) {
      this.$axios({ method: "GET", url: `https://api.riskomer.com/version/new-version-updated/${versionType}`, baseURL })
        .then(() => {
          this.$emitter?.$emit("Notification", { title: "Başarılı", variant: "success" });
        })
        .catch((err) => {
          console.log(err);
          this.$emitter?.$emit("Notification", { title: "Hata", variant: "danger" });
        });
    },
  },
};
</script>

<style></style>
